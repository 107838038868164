<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface mb-10"
        >
          <admin-announcements-view/>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import AdminAnnouncementsView from "@/components/views/AdminAnnouncementsView";
import {mapState} from "vuex";

export default {
  name: "AdminAnnouncements",
  components: {AdminAnnouncementsView},
  computed: {
    ...mapState("app", ["loading"])
  }
}
</script>

<style scoped>

</style>