<template>
  <div class="surface">
    <v-sheet rounded="lg" class="background pa-3">
      <h1>Manage Announcements</h1>
      <div class="body-1">Create, modify and publish announcements to groups in your area of responsibility</div>
      <v-divider class="my-2"/>
      <!--      <app-btn class="pa-3" to="/administration/announcements/announcement">Add</app-btn>-->
    </v-sheet>
    <v-data-table :items="posts" :headers="headers">
      <template v-slot:item.detail="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        >{{ removeTags(item.detail).substring(0, 40) }}...</span>
          </template>
          <span>{{ removeTags(item.detail) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.dateFrom="{ item }">
        <div class="caption">{{ item.dateTo | moment('DD MMM YYYY') }}</div>
        <div class="caption">{{ item.dateTo | moment('HH:mm Z') }}</div>
      </template>
      <template v-slot:item.dateTo="{ item }">
        <div class="caption">{{ item.dateTo | moment('DD MMM YYYY') }}</div>
        <div class="caption">{{ item.dateTo | moment('HH:mm Z') }}</div>
      </template>

      <template v-slot:item.priorityLevelText="{ item }">
        <v-chip label x-small text-color="white" :color="getPriorityInfo(item.priority).color">
          {{ item.priorityLevelText }}
        </v-chip>
      </template>

      <template v-slot:item.isPublished="{ item }">
        <v-icon small :color="item.isPublished ? 'success': undefined">
          mdi-{{ item.isPublished ? 'check-circle' : 'circle-outline' }}
        </v-icon>
      </template>
      <template v-slot:item.postsDisabled="{ item }">
        <v-icon small :color="item.postsDisabled ? undefined:'success'">
          mdi-{{ item.postsDisabled ? 'circle-outline' : 'check-circle' }}
        </v-icon>
      </template>
      <template v-slot:item.isSticky="{ item }">
        <v-icon small :color="item.isSticky ? 'success': undefined">
          mdi-{{ item.isSticky ? 'check-circle' : 'circle-outline' }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <post-action-menu :post-id="item._id" :is-author="true" v-on:deletePost="deletePost(item._id)"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import PostActionMenu from "@/components/shared/PostActionMenu";
import {PostHttp} from "@/http/PostHttp";

export default {
  name: "AdminAnnouncementsView",
  components: {PostActionMenu},
  async mounted() {
    await this.setPosts
  },
  data: () => ({
    headers: [
      {text: "Priority Level", value: "priorityLevelText"},
      {text: "Poster", value: "authorName"},
      {text: "Valid From", value: "dateFrom"},
      {text: "Valid To", value: "dateTo"},
      {text: "Detail", value: "detail"},
      {text: "Published?", value: "isPublished"},
      {text: "Allow Comments?", value: "postsDisabled"},
      {text: "Sticky Post?", value: "isSticky"},
      {text: "Actions", value: "actions", sortable: false}
    ]
  }),
  computed: {
    ...mapState("post", ['posts']),
    ...mapState("person", ["persons"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("priorityLevels", ["priorityLevels"]),
  },
  methods: {
    ...mapActions("person", ["setPersons"]),
    ...mapActions("post", ["setPosts", "setActivePost"]),
    ...mapActions("app", ["setToast"]),
    removeTags(str) {
      if ((str === null) || (str === ''))
        return false;
      else
        str = str.toString().replace('&nbsp;', ' ').replace('&#39;', '\'').replace('&rsquo;', '\'');

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(&gt;)(?:&nbsp;|<br>)+(\s?&lt;)/g, ' ').replace(/(<([^>]+)>)/ig, ' ');
    },
    getPriorityInfo(priorityLevel) {
      return this.priorityLevels.find(i => {
        return i.value == priorityLevel
      })
    },
    canDelete() {
      return true
    },
    async viewPost(post) {
      await this.setActivePost(post)
      await this.$router.push('/administration/announcements/announcement')
    },
    async deletePost(id) {
      if (confirm(`are you sure you want to delete this announcement?`)) {

        try {
          this.isLoading = true;
          await PostHttp.deletePost(id)
          await this.setPosts
          this.setToast({color: 'success', text: 'Announcement deleted.', show: true});
        } catch (err) {
          if (err.response) {
            console.log(err.response.data)
          } else {
            console.log(err)
          }
        } finally {
          this.isLoading = false;
        }
      }


    }
  }
}
</script>

<style scoped>
.announcement {
}

.announcement.Critical {
  border-left: 5px solid tomato;
}

.announcement.Priority {
  border-left: 5px solid orange;
}

.announcement.Routine {
  border-left: 5px solid blue;
}
</style>